
  import { Component, Vue } from 'vue-property-decorator';
  import { Action, Getter, State as StateClass } from 'vuex-class';
  import { ADD_TOAST_MESSAGE as addToastMessage } from 'vuex-toast';
  import { State } from '@/models/State';
  import { ManagerRole } from '@/models/manager/Manager';

  @Component({
    components: {},
  })
  export default class PaymentsTable extends Vue {
    options = {
      headings: {
        assetName: 'Asset name',
        investorName: 'Investor name',
        amount: 'Amount',
        createdDate: 'Date',
      },
      filterable: ['assetName', 'investorName', 'createdDate'],
      // columnsClasses strings need to have a space at the end
      // because vue-tables-2 adds classes runtime without a space before
      columnsClasses: {
        assetName: 'table__col--name align-middle table__col--s ',
        investorName: 'table__col--sold align-middle table__col--xs ',
        amount: 'table__col--sharesAvailable align-middle table__col--xs ',
        createdDate: 'table__col--totalValueShares align-middle table__col--xs ',
      },
      skin: 'table table-sm table-nowrap card-table table--fixed', // This will add CSS classes to the main table
    };

    @Getter getCurrentManagerRole!: ManagerRole;

    @StateClass blockchainPayments!: State['blockchainPayments'];
    @Action(addToastMessage) addToastMessage!: Function;

    get columns(): string[] {
      const columns = [
        'assetName', 'investorName', 'amount', 'createdDate',
      ];
      return columns;
    }

    /**
     * Returns assets count.
     */
    get assetCount(): number {
      return this.blockchainPayments.length;
    }
  }
