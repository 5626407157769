
  import { Vue, Component } from 'vue-property-decorator';
  import PaymentsTable from '@/components/payments/PaymentsTable.vue';

  @Component({
    components: {
      PaymentsTable,
    },
  })

  export default class Blockchain extends Vue {}
